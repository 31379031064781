<template>
  <div class="new-build-site">
    <!-- 伙伴云：新建    选择模板    菜单-->
    <div class="title">{{ $t("label_partner_new") }}</div>
    <div class="template">
      <div class="title">{{ $t("label.emailobject.templates") }}</div>
      <div class="item">
        <el-radio v-model="radio" :label="$t('label_partner_workspace_tab')">{{
          $t("label_partner_workspace_tab")
        }}</el-radio>
      </div>
    </div>
    <div class="template">
      <!-- 基本信息  伙伴云名称  请输入伙伴云名称  -->
      <div class="title">{{ $t("chatter.group.baseInformation") }}</div>
      <div class="item">
        {{ $t("label_partner_all_name") }}<el-input v-model="name"></el-input>
        <span v-show="nameNull">{{
          $t("label.partnerCloud.partnerCloudName")
        }}</span>
      </div>
      <!-- 伙伴云地址  检查可用性  -->
      <div class="item">
        {{ $t("label.partner.setup.address") }} {{ baseUrl
        }}<el-input v-model="addr"></el-input>
        <div class="button" @click="availabilityCheck">
          {{ $t("label.partner.setup.Enable") }}
        </div>
        <span
          v-show="urlSuccess != ''"
          :style="{ color: urlSuccess == 'yes' ? '#06844B' : '#cc463d' }"
          >{{ urlMessage }}</span
        >
      </div>
      <!-- 描述 -->
      <div class="item">
        {{ $t("label.appointmentdetail.wizard.title13") }}
        <el-input v-model="des" type="textarea"></el-input>
      </div>
    </div>
    <div class="bottom">
      <!-- 取消  保存  -->
      <el-button @click="cancelNewBuid">{{
        $t("label.emailsync.button.cancel")
      }}</el-button>
      <el-button type="primary" @click="save">{{
        $t("component.telerecord.button.save")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  checkPartnerSecond,
  getPartnerFirstUrl,
  savePartner,
} from "./api"; //获取域名

export default {
  data() {
    return {
      //域名提示信息
      urlMessage: "",
      //判断是否展示域名提示信息
      urlSuccess: "",
      //保存时判断伙伴云名称是否填写
      nameNull: false,
      //一级域名
      baseUrl: "",
      radio: this.$i18n.t("label_partner_workspace_tab"), //菜单
      //伙伴云名称
      name: "",
      //伙伴云地址
      addr: "",
      //描述
      des: "",
    };
  },
  mounted() {
    this.getPartnerFirstUrl();
  },
  watch: {
    addr() {
      this.urlMessage = "";
    },
    name(newVal, oldVal) {
      if (newVal != oldVal && newVal != "") {
        this.nameNull = false;
      }
    },
  },
  methods: {
    //查询伙伴云一级域名
    async getPartnerFirstUrl() {
      let res = await getPartnerFirstUrl();
      if (res.result) {
        this.baseUrl = res.data.parterfirstUrl;
      }
    },
    // 检测可用性
    async availabilityCheck() {
      if (this.addr == "") {
        return false;
      }
      let result = await checkPartnerSecond({ second: this.addr });
      if (result.result) {
        if (result.returnCode != "1") {
          this.urlMessage = result.data;
          this.urlSuccess = "no";
        } else if (result.returnCode == "1") {
          this.urlMessage = this.$i18n.t(
            "label.partnerCloud.basicSetting.available"
          ); //域名可用
          this.urlSuccess = "yes";
        }
      }
    },
    //取消新建
    cancelNewBuid() {
      this.$emit("closeNewBuild");
    },
    //保存新建
    async save() {
      let option = {
        name: this.name, //名称
        dir_2rd: this.addr, //二级域名
        description: this.des,
        template: this.radio == "" ? "" : "tab",
      };
      if (this.name == "") {
        this.nameNull = true;
      }
      if (this.addr == "") {
        this.urlMessage = this.$i18n.t(
          "label.partnerCloud.newBuildSite.enterDomain"
        ); //请输入域名
        this.urlSuccess = "nono";
      }
      if (this.name == "" || this.addr == "" || this.urlMessage == "no") {
        return false;
      }
      let result = await checkPartnerSecond({ second: this.addr });
      if (result.result) {
        if (result.returnCode != "1") {
          this.urlMessage = result.data;
          this.urlSuccess = "no";
          return false;
        } else if (result.returnCode == "1") {
          this.urlMessage = this.$i18n.t(
            "label.partnerCloud.basicSetting.available"
          ); //域名可用
          this.urlSuccess = "yes";
        }
      }
      let res = await savePartner(option);
      if (res.result == true) {
        this.$emit("closeNewBuild");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-button {
  padding: 8px 19px;
}
.new-build-site {
  color: #080707;
  font-size: 12px;
  height: 100%;
  position: relative;
  ::v-deep .el-table td {
    vertical-align: middle;
  }
  .title {
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
  }
  .template {
    .title {
      height: 34px;
      line-height: 34px;
      background: #f3f2f2;
      padding-left: 10px;
    }
    .item {
      padding-left: 30px;
      height: 58px;
      line-height: 58px;
      span {
        margin-left: 30px;
        color: #cc463d;
      }
      ::v-deep .el-radio__label {
        font-size: 12px;
      }
      ::v-deep .el-input {
        width: 200px;
        margin-left: 10px;
      }
      ::v-deep .el-textarea {
        width: 300px;
        margin-left: 30px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
      }
      .button {
        padding: 0 16px;
        color: white;
        background: #006dcc;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin-left: 13px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
  .bottom {
    position: absolute;
    width: 100%;
    height: 60px;
    border-top: 1px solid #dddbda;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>