var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site-list"},[_c('div',[_vm._v(_vm._s(_vm.$t("label_partner_all_memo")))]),_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("label.partnerCloud.maximumNumber"))+_vm._s(_vm.maxNumber)+" ")]),_c('div',{staticClass:"btn-box"},[_c('el-button',{staticClass:"new-build",attrs:{"type":"primary"},on:{"click":_vm.newBulidSite}},[_vm._v(" "+_vm._s(_vm.$t("label.import.page1.newcreate"))+" ")])],1),_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-box-table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"row-style":{ height: '40px' },"cell-style":{ padding: '0px', color: '#333', fontSize: '14px' },"header-cell-style":{
          background: '#F5F5F5',
          color: '#333',
          height: '40px',
          padding: '0',
          'font-size': '12px',
          'border-right': '1px solid #dcdcdc',
        }}},[_c('el-table-column',{attrs:{"min-width":"50%","prop":"name","label":_vm.$t('label.emailtocloudcc.name')}}),_c('el-table-column',{attrs:{"min-width":"50%","prop":"description","label":_vm.$t('label.appointmentdetail.wizard.title13')}}),_c('el-table-column',{attrs:{"min-width":"70%","prop":"dir_2rd","label":_vm.$t('AD')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:{ active: _vm.showItemTab ? true : false },on:{"click":function($event){return _vm.handleImEvent('jump', scope.row)}}},[_vm._v(" "+_vm._s(scope.row.dir_2rd)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"30%","prop":"isusing","label":_vm.$t('label.import.index.state')}}),_c('el-table-column',{attrs:{"min-width":"30%","label":_vm.$t('label.import.index.operation'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.showItemTab)?_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('edit', scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t("label.tabpage.editz")))]):_vm._e(),(
                _vm.showItemTab && scope.row.status == 'false'
                // &&
                // scope.row.objid != 'lead' &&
                //scope.row.objid != 'opportunity'
              )?_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('enable', scope.row)}}},[_vm._v(_vm._s(_vm.$t("label.emailtocloudcc.button.enable")))]):_vm._e(),(
                _vm.showItemTab && scope.row.status == 'true'
                // &&
                // scope.row.objid != 'lead' &&
                // scope.row.objid != 'opportunity'
              )?_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('disable', scope.row)}}},[_vm._v(_vm._s(_vm.$t("label.emailsync.status.disabel")))]):_vm._e()]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }