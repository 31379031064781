<template>
  <div class="partner-cloud-setting">
    <site-list
      v-if="isShowSiteList"
      @changeStatus="changeStatus"
      @openEdit="openEdit"
    ></site-list>
    <new-build-site
      v-if="isShowNewBuild"
      @closeNewBuild="closeNewBuild"
    ></new-build-site>
    <edit-site
      v-if="isShowEdit"
      @closeEdit="closeEdit"
      :editSiteData="editSiteData"
    ></edit-site>
  </div>
</template>

<script>
import siteList from "@/views/systemSettings/components/partnerCloudSetting/components/siteList.vue";
import newBuildSite from "@/views/systemSettings/components/partnerCloudSetting/components/newBuildSite.vue";
import editSite from "@/views/systemSettings/components/partnerCloudSetting/components/editSite.vue";
export default {
  data() {
    return {
      //判断是否展示站点列表
      isShowSiteList: false,
      //判断是否展示新建站点页面
      isShowNewBuild: false,
      //判断是否展示编辑站点页面
      isShowEdit: false,
      //当前编辑站点信息
      editSiteData: {},
    };
  },
  watch: {
    queryDate() {},
  },
  methods: {
    //更改站点列表的展示状态
    changeStatus() {
      this.isShowSiteList = false;
      this.isShowEdit = false;
      this.isShowNewBuild = true;
    },
    //更改新建站点的展示状态
    closeNewBuild() {
      this.isShowNewBuild = false;
      this.isShowEdit = false;
      this.isShowSiteList = true;
    },
    //更改编辑站点的展示状态
    closeEdit() {
      this.isShowEdit = false;
      this.isShowNewBuild = false;
      this.isShowSiteList = true;
    },
    //编辑站点
    openEdit(data) {
      this.editSiteData = data;
      this.isShowNewBuild = false;
      this.isShowSiteList = false;
      this.isShowEdit = true;
    },
  },
  mounted() {
    this.isShowSiteList = true;
  },
  components: {
    siteList,
    newBuildSite,
    editSite,
  },
};
</script>

<style lang="scss" scoped>
.partner-cloud-setting {
  height: 100%;
}
</style>
