<template>
  <div class="site-list">
    <!-- 列表显示贵组织中的全部伙伴云。单击URL可将您直接跳转到登录页。   伙伴站点的最大数量：  -->
    <div>{{ $t("label_partner_all_memo") }}</div>
    <div style="font-weight: bold">
      {{ $t("label.partnerCloud.maximumNumber") }}{{ maxNumber }}
    </div>
    <div class="btn-box">
      <el-button type="primary" @click="newBulidSite" class="new-build">
        <!-- 新建 -->
        {{ $t("label.import.page1.newcreate") }}
      </el-button>
    </div>
    <div class="table-box">
      <div class="table-box-table">
        <el-table
          v-loading="isLoading"
          :data="tableData"
          :row-style="{ height: '40px' }"
          :cell-style="{ padding: '0px', color: '#333', fontSize: '14px' }"
          :header-cell-style="{
            background: '#F5F5F5',
            color: '#333',
            height: '40px',
            padding: '0',
            'font-size': '12px',
            'border-right': '1px solid #dcdcdc',
          }"
          style="width: 100%"
        >
          <!-- 对象，启用，最后修改人，操作 -->
          <!-- 名称  描述  地址 -->
          <el-table-column
            min-width="50%"
            prop="name"
            :label="$t('label.emailtocloudcc.name')"
          >
          </el-table-column>
          <el-table-column
            min-width="50%"
            prop="description"
            :label="$t('label.appointmentdetail.wizard.title13')"
          >
          </el-table-column>
          <el-table-column min-width="70%" prop="dir_2rd" :label="$t('AD')">
            <template slot-scope="scope">
              <div
                :class="{ active: showItemTab ? true : false }"
                @click="handleImEvent('jump', scope.row)"
              >
                {{ scope.row.dir_2rd }}
              </div>
            </template>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column
            min-width="30%"
            prop="isusing"
            :label="$t('label.import.index.state')"
          >
          </el-table-column>
          <el-table-column
            min-width="30%"
            :label="$t('label.import.index.operation')"
            width="180"
          >
            <template slot-scope="scope">
              <el-button
                v-if="showItemTab"
                @click="handleImEvent('edit', scope.row)"
                type="text"
                size="small"
                class="im-event-item"
              >
                {{ $t("label.tabpage.editz") }}</el-button
              >
              <!-- 启用 -->
              <el-button
                v-if="
                  showItemTab && scope.row.status == 'false'
                  // &&
                  // scope.row.objid != 'lead' &&
                  //scope.row.objid != 'opportunity'
                "
                @click="handleImEvent('enable', scope.row)"
                type="text"
                size="small"
                class="im-event-item"
                >{{ $t("label.emailtocloudcc.button.enable") }}</el-button
              >
              <!-- 禁用 -->
              <el-button
                v-if="
                  showItemTab && scope.row.status == 'true'
                  // &&
                  // scope.row.objid != 'lead' &&
                  // scope.row.objid != 'opportunity'
                "
                @click="handleImEvent('disable', scope.row)"
                type="text"
                size="small"
                class="im-event-item"
                >{{ $t("label.emailsync.status.disabel") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
  <script>
import {
  queryPartnerSetup,
  queryPartnerList,
  getPartnerFirstUrl,
  savePartner,
  checkPartnerNum,
} from "./api";

export default {
  data() {
    return {
      isLoading: false,
      tableData: [], //站点列表
      maxNumber: 0,
      showItemTab: false,
    };
  },
  mounted() {
    this.getShowTab();
    this.queryPartnerList();
  },
  methods: {
    // 获取是否展示伙伴云伙伴站点以及伙伴财务设置
    async getShowTab() {
      let result = await queryPartnerSetup();
      if (result.result) {
        this.showItemTab =
          result.data.partnerworkspace == "false" ? false : true;
      }
    },
    //更改禁用与启用
    async saveEdit(id, isusing) {
      let option = {
        id: id,
        isusing: isusing,
      };
      let res = await savePartner(option);
      if (res.result == true) {
        this.queryPartnerList();
      }
    },
    //查询伙伴云一级域名
    async getPartnerFirstUrl(data) {
      let res = await getPartnerFirstUrl();
      if (res.result) {
        data.map((item) => {
          item.dir_2rd = res.data.parterfirstUrl + item.dir_2rd;
          item.status = item.isusing;
          item.baseUrl = res.data.parterfirstUrl;
          if (item.isusing == "true") {
            item.isusing = this.$i18n.t("label.emailtocloudcc.button.enable"); //启用
          } else if (item.isusing == "false") {
            item.isusing = this.$i18n.t("label.emailsync.status.disabel"); //禁用
          }
        });
        this.tableData = data;
      }
    },
    // 获取站点列表
    async queryPartnerList() {
      this.isLoading = true;
      let res = await queryPartnerList();
      if (res.result && res.data.length > 0) {
        this.isLoading = false;
        this.maxNumber = res.data[0].substr(4);
        this.getPartnerFirstUrl(res.data[1]);
        this.tableData = res.data;
      } else {
        this.isLoading = false;
        this.maxNumber = res.data[0].substr(4);
        this.tableData = [];
      }
    },
    // 操作：编辑，删除，启用，禁用
    handleImEvent(operation, data) {
      if (operation == "edit") {
        this.$router.push({
          path: "editSite",
          query: { editData: JSON.stringify(data) },
        });
        // this.$emit('openEdit',data)
      } else if (operation == "enable") {
        this.saveEdit(data.id, "true");
      } else if (operation == "disable") {
        this.saveEdit(data.id, "false");
      } else if (operation == "jump") {
        if (this.showItemTab) {
          window.location.href = data.dir_2rd;
        }
      }
    },
    // 新建站点
    async newBulidSite() {
      let result = await checkPartnerNum();
      if (result.result) {
        if (result.returnCode == "1") {
          this.$emit("changeStatus", false);
        } else {
          this.$message.warning(result.data);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #006dcc !important;
}
::v-deep .el-table td {
  vertical-align: middle !important;
}
.site-list {
  padding: 30px 10px;
  .title {
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
  }
  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .new-build {
      background: #006dcc;
      border-color: #006dcc;
      width: 176px;
      height: 45px;
      font-size: 14px;
    }
  }
  .table-box {
    ::v-deep .el-table .cell {
      font-size: 12px;
    }
    margin-top: 40px;
    margin: 40px 2% 0;
    &-table {
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      .im-event-item {
        color: #006dcc;
        cursor: pointer;
      }
      .active {
        color: #006dcc;
        cursor: pointer;
      }
      .noActive {
        color: #333;
        cursor: unset;
      }
    }
  }
}
</style>